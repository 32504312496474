<template>
  <v-container fluid>
    <v-row>
      <v-col class="ml-2" lg="2" sm="2">
        <v-autocomplete
          :items="venueServices"
          v-model="venueServiceIds"
          item-value="venue_service_id"
          item-text="name"
          outlined
          multiple
          @change="loadOutlets"
          placeholder="Services"
          class="q-autocomplete shadow-0"
          dense
          hide-details="hide-details"
          height="44"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleSelect()">
              <v-list-item-action>
                <v-icon
                  :color="venueServiceIds.length > 0 ? 'indigo darken-4' : ''"
                  >{{ getIcon() }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template
            v-if="venueServiceIds.length == venueServices.length"
            v-slot:selection="{ index }"
          >
            <span v-if="index == 0">All Services</span>
          </template>
          <template v-else v-slot:selection="{ item, index }">
            <span v-if="index == 0">{{ item.name }}</span>
            <span v-if="index == 1">, {{ item.name }}</span>
            <span v-if="index == 2">, ...</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col lg="8" sm="8" class="text-center">
        <v-btn-toggle
          borderless
          class="q-tabs shadow-0"
          v-model="outletStatus"
          mandatory
          @change="loadOutlets"
        >
          <v-btn value="active" height="44" style="height: 44px !important"
            >Active</v-btn
          >
          <v-btn value="inactive" height="44" style="height: 44px !important"
            >Inactive</v-btn
          >
          <!-- <v-btn value="external">External</v-btn> -->
        </v-btn-toggle>
      </v-col>
      <v-col class="text-lg-right">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              dark
              height="45"
              class="mr-2 blue-color"
              @click="gotoConfiguration"
              >Configuration <v-icon right dark>mdi-cog</v-icon></v-btn
            >
          </template>
          Configuration
        </v-tooltip> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color=" darken-1"
              class="white--text blue-color"
              text
              v-if="checkWritePermission($modules.outlet.management.slug)"
              @click="addOutlet"
            >
              + Add Outlet
            </v-btn>
          </template>
          + Add Outlet
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="outlet in outletList" :key="outlet.id" lg="4" md="4" xl="3">
        <outlet-widget
          v-bind="outlet"
          @edit="outletDetails"
          @delete="confirmDelete"
        />
      </v-col>
    </v-row>
    <h3 class="text-center mt-12" v-if="outletList.length == 0">No Outlets</h3>

    <v-pagination
      v-if="totalPages"
      v-model="page"
      :length="totalPages"
      class="mt-3 new-pagination"
    ></v-pagination>

    <outlet-add-edit-modal
      :add-outlet-dialog="addOutletDialog"
      :roles="roles"
      :venue-services="venueServices"
      :venue-service-ids="venueServiceIds"
      @cancelOutlet="cancelOutlet"
    />
    <v-dialog v-model="addOutletDialog" scrollable width="35%" v-if="1 == 3">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text>
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon
                    class="text-2xl font-semibold"
                    :text="`${outlet.id != null ? 'Update ' : 'Add '} Outlet`"
                    style="color: black"
                  >
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="cancelOutlet">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <v-row dense justify="end">
              <div class="col-md-3 d-flex gap-x-4 justify-end">
                <label for="" class="d-flex text-center align-center"
                  >Enable Online</label
                >
                <v-switch
                  :false-value="0"
                  :true-value="1"
                  v-model="outlet.is_public"
                  class="d-flex text-center align-center"
                ></v-switch>
              </div>
            </v-row>
            <v-row dense>
              <v-col md="4" lg="4">
                <image-uploader
                  @upload="
                    (data) => {
                      outlet.profile_image = data;
                    }
                  "
                  :image_path="outlet.image_path"
                  :height="200"
                  defaultImage="outlet"
                  @remove="
                    () => {
                      outlet.profile_image = null;
                    }
                  "
                  ref="image_upload"
                ></image-uploader>
              </v-col>
              <v-col md="8" lg="8">
                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <label for="">Contact Person First name*</label>
                    <v-text-field
                      v-model="outlet.first_name"
                      outlined
                      required
                      :rules="[(v) => !!v || 'First name is required']"
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      validate-on-blur
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <label for="">Contact Person Last name*</label>

                    <v-text-field
                      v-model="outlet.last_name"
                      outlined
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      validate-on-blur
                      dense
                      required
                      :rules="[(v) => !!v || 'Last name is required']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <label for="">Outlet Name*</label>
                    <v-text-field
                      v-model="outlet.name"
                      outlined
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      validate-on-blur
                      dense
                      required
                      :rules="[(v) => !!v || 'Outlet Name is required']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <label for="">Email*</label>
                    <v-text-field
                      v-model="outlet.email"
                      outlined
                      class="q-text-field shadow-0"
                      dense
                      required
                      :rules="[
                        (v) => !!v || 'E-mail is required',
                        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                      ]"
                      hide-details="auto"
                      validate-on-blur
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col cols="12" sm="6" md="6">
                <label for="">Phone*</label>
                <mobile-number-field
                  v-model="outlet.mobile"
                  outlined
                  required
                  :dense="true"
                  :variant="2"
                ></mobile-number-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label for="">Username*</label>

                <v-text-field
                  v-model="outlet.username"
                  outlined
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  validate-on-blur
                  dense
                  autocomplete="off"
                  :name="`username_${Math.random()}`"
                  required
                  :rules="[(v) => !!v || 'Username is required']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense v-if="!editFlag">
              <v-col cols="12" md="6">
                <label for="">Password*</label>

                <v-text-field
                  :type="outlet.hidePassword1 ? 'text' : 'password'"
                  :append-icon="
                    outlet.hidePassword1 ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  outlined
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  validate-on-blur
                  dense
                  v-model="outlet.password"
                  @click:append="outlet.hidePassword1 = !outlet.hidePassword1"
                  required
                  autocomplete="new-password"
                  :name="`password_${Math.random()}`"
                  :rules="[(v) => !!v || 'Password is required']"
                />
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Confirm password*</label>
                <v-text-field
                  :type="outlet.hidePassword2 ? 'text' : 'password'"
                  :append-icon="
                    outlet.hidePassword2 ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  outlined
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                  :autocomplete="`outlet-password-confirm-${parseInt(
                    outlet.name
                  )}`"
                  v-model="outlet.password_confirm"
                  @click:append="outlet.hidePassword2 = !outlet.hidePassword2"
                  required
                  :rules="[
                    (v) => !!v || 'Password is required',
                    (v) =>
                      v == this.outlet.password || 'Password does not match',
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="4">
                <label for="">Roles*</label>
                <v-select
                  v-model="outlet.role_id"
                  :items="roles"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  validate-on-blur
                  dense
                  item-text="title"
                  :rules="[(v) => !!v || 'Role is required']"
                ></v-select>
              </v-col>
              <v-col md="4">
                <label for="">Outlet Service*</label>
                <v-select
                  v-model="outlet.venue_service_ids"
                  :items="venueServices"
                  item-text="name"
                  multiple
                  item-value="venue_service_id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  validate-on-blur
                  dense
                  required
                  :rules="[(v) => v.length > 0 || 'Service is required']"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggleSelectOutlet()">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            venueServiceIds.length > 0 ? 'indigo darken-4' : ''
                          "
                          >{{ getIconOutlet() }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template
                    v-if="
                      outlet.venue_service_ids &&
                      venueServices.length == outlet.venue_service_ids.length
                    "
                    v-slot:selection="{ index }"
                  >
                    <span v-if="index == 0">All Services</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index == 0">{{ item.name }}</span>
                    <span v-if="index == 1">, ...</span>
                  </template>
                </v-select>
              </v-col>
              <v-col
                md="4"
                v-if="checkWritePermission($modules.outlet.commission.slug)"
              >
                <label for="">Commission</label>

                <v-text-field
                  v-model="outlet.commission_percentage"
                  :rules="percentageRules"
                  type="text"
                  required
                  outlined
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  validate-on-blur
                  dense
                  append-icon="mdi-percent"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-2 white--text blue-color" @click="cancelOutlet"
              >Close</v-btn
            >

            <v-btn
              class="ma-2 white--text teal-color"
              @click="addOrUpdateOutlet"
              >{{ outlet.id != null ? "Update" : "Save" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import OutletWidget from "@/components/Outlet/OutletWidget.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import MobileNumberField from "@/components/Fields/MobileNumberField.vue";
import OutletAddEditModal from "@/views/Outlets/OutletAddEditModal.vue";
export default {
  components: {
    OutletAddEditModal,
    MobileNumberField,
    ImageUploader,
    SvgIcon,
    OutletWidget,
  },
  mounted() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
    this.getVenueRolesList();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
        (service) => service.name != "POS"
      );
    },
  },
  watch: {
    page() {
      this.loadOutlets();
    },
  },
  data() {
    return {
      percentageRules: [
        (value) => {
          if (value && !/^\d+(\.\d+)?%?$/.test(value)) {
            return "Invalid percentage format";
          }
          return true;
        },
      ],
      outlet: {
        is_public: 1,
        first_name: null,
        last_name: null,
        email: null,
        mobile: null,
        profile_image: null,
        username: null,
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        venue_service_ids: [],
        status_id: 1,
        commission_percentage: null,
      },
      roles: [],
      page: 1,
      btnShow: false,
      venueServiceIds: [],
      outletStatus: null,
      outletList: [],
      addOutletDialog: false,
      totalPages: null,
      valid: true,
      editFlag: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  methods: {
    getVenueRolesList() {
      this.$http
        .get("venues/roles")
        .then((response) => {
          if (response.status == 200) this.roles = response.data.data;
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (this.venueServices.length == this.venueServiceIds.length) {
          this.venueServiceIds = [];
        } else {
          this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.loadOutlets();
      });
    },
    getIcon() {
      if (this.venueServiceIds.length == 0) return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.venueServiceIds.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    toggleSelectOutlet() {
      this.$nextTick(() => {
        if (this.venueServices.length == this.outlet.venue_service_ids.length) {
          this.outlet.venue_service_ids = [];
        } else {
          this.outlet.venue_service_ids = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.loadOutlets();
      });
    },
    getIconOutlet() {
      if (this.outlet.venue_service_ids.length == 0)
        return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.outlet.venue_service_ids.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    loadOutlets() {
      let url = `&status=${this.outletStatus}&per_page=9`;
      this.showLoader("Loading Outlets");
      if (this.venueServiceIds.length > 0) {
        this.venueServiceIds.map((service, index) => {
          url += `&venue_service_id[${index}]=${service}`;
        });
      }
      this.$http
        .get(`venues/outlets?page=${this.page}${url}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.outletList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    addOutlet() {
      this.addOutletDialog = true;
      this.editFlag = false;
      //   setTimeout(() => {
      //     this.$refs["image_upload"].cancel();
      //     this.$refs.form.resetValidation();
      //   });
    },

    addOrUpdateOutlet(e) {
      if (e != null) {
        e.preventDefault();
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
      }

      let formData = new FormData();
      this.showLoader();
      if (
        this.outlet.profile_image != null &&
        typeof this.outlet.profile_image != "string"
      )
        formData.append("image_path", this.outlet.profile_image);
      formData.append("is_public", this.outlet.is_public);
      formData.append("name", this.outlet.name);
      formData.append("first_name", this.outlet.first_name);
      formData.append("last_name", this.outlet.last_name);
      formData.append("email", this.outlet.email);
      formData.append("mobile", this.outlet.mobile);
      formData.append("username", this.outlet.username);
      formData.append(
        "commission_percentage",
        this.outlet.commission_percentage
      );
      if (this.outlet.id) {
        formData.append("outlet_id", this.outlet.id);
      }

      if (this.outlet.password)
        formData.append("password", this.outlet.password);
      if (this.outlet.role_id) formData.append("role_id", this.outlet.role_id);

      formData.append("status_id", this.outlet.status_id);
      if (this.outlet.venue_service_ids.length)
        this.outlet.venue_service_ids.forEach((venueServiceId, index) => {
          formData.append(`venue_service_ids[${index}]`, venueServiceId);
        });
      this.$http
        .post(
          "venues/outlets" +
            (this.outlet.id != null ? `/${this.outlet.id}` : ""),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message = response.data.message;
            this.showSuccess(message);
            this.hideLoader();
            this.loadOutlets();
            this.addOutletDialog = false;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    cancelOutlet() {
      this.addOutletDialog = false;
      this.loadOutlets();
    },
    outletDetails(id) {
      if (!id) return;
      this.$http
        .get(`venues/outlets/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.outlet = response.data.data;
            this.outlet.status_id = this.outlet.status_id.toString();
            this.outlet.profile_image = this.outlet.image_path
              ? this.outlet.image_path
              : null;
            this.outlet.venue_service_ids = this.outlet.outlet_services.map(
              (item) => item.venue_service_id
            );
            this.addOutletDialog = true;
            this.editFlag = true;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    confirmDelete(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${
          data.status_id == 1 ? "delete" : "reactivate"
        } this outlet?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "outlet",
        data: {
          flag: data.flag ? true : false,
        },
      };
    },
    confirmActions(data) {
      if (data.type == "outlet") this.changeOutletStatus(data.id);
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    changeOutletStatus(id) {
      this.$http
        .put(`venues/outlets/${id}/status`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.loadOutlets();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>
<style>
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.olBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
</style>
