<template
  >
  <v-card
    style="border-radius: 8px"
    class="shadow trainer_card"
    @click="viewOutlet(id)"
  >
    <v-card-text>
      <v-row>
        <v-col md="5">
          <view-image
            :image="image_path"
            defaultImage="user"
            style="border-radius: 0.5rem"
            :height="130"
          ></view-image>
        </v-col>
        <v-col md="7">
          <div class="mt-4">
            <p class="font-semibold text-lg black--text text-elepsis">
              {{ name }}
            </p>
            <div class="d-flex gap-x-2 align-center mt-5 stats_col">
              <SMSIcon />
              <span class="black--text text-base text-elepsis">{{
                email
              }}</span>
            </div>
            <div class="d-flex gap-x-2 align-center mt-2 stats_col">
              <CallIcon />
              <span class="black--text text-base email_trainer">{{
                mobile
              }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import SMSIcon from "@/assets/images/misc/sms.svg";
//import SvgIcon from "@/components/Image/SvgIcon.vue";
import CallIcon from "@/assets/images/misc/call.svg";

export default {
  components: { CallIcon, SMSIcon },
  props: {
    id: { type: Number, default: 0 },
    name: { type: String, default: "" },
    first_name: { type: String, default: "" },
    last_name: { type: String, default: "" },
    email: { type: String, default: "" },
    mobile: { type: String, default: "" },
    sales: { type: Number, default: 0 },
    bookings: { type: Number, default: 0 },
    status_id: { type: Number, default: 1 },
    image_path: {
      type: String,
      default: null,
    },
  },
  methods: {
    viewOutlet(id) {
      this.$router.push({
        name: "OutletView",
        params: { data: btoa(id) },
      });
    },
    getWidth() {
      return "32%";
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
};
</script>
<style scoped>
.trainer_card {
  border: 1px solid rgba(17, 42, 70, 0);
  cursor: pointer;
}

.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 85%;
  overflow: hidden;
}
.trainer_card:hover {
  border: 1px solid rgba(17, 42, 70, 1);
}
</style>
